import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectProvider } from "../../../action/managerProviders";
import * as providersServices from '../../../service/provider'

import { IconButton,Avatar } from "@mui/material"
import CustomAvatar from "../../Avatar"

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import './styles.css'

const PaperManagerProvider = ({
    provider,
    add
}) => {
    const dispatch = useDispatch()

    const activeServices = useSelector(state => state.services.list)
    const providerSelected = useSelector(state => state.managerProviders.selected)

    const [loading, setLoading] = useState(false)

    const handleOnDelete = async () => {
        setLoading(true)
        const promises = providerSelected.services.map(async(item) => {
            const serviceData = activeServices.filter(a => a.service === item.service)[0]
                
            await providersServices.updateProvider({
                ...provider,
                delete: true,
                status: "inactive",
                uuid: item.uuidProvider,
                service: serviceData.service,
                uuidService: serviceData.uuid,
                uuidCompany: serviceData.uuidCompany,
                identityCompany: serviceData.identityCompany,
            })
        })

        await Promise.all(promises)
        window.location.reload()
        setLoading(false)
    }

    if (add) {
        return (
            <button 
                className='paper-service-container'
                onClick={() => dispatch(selectProvider({action: "add"}))}
            >
                <Avatar>
                    <AddIcon />
                </Avatar>
                <div className='paper-service-content'>
                    <div className='paper-service-body'>
                        <span className='paper-service-title'>Cadastrar Novo Prestador</span>
                    </div>
                </div>
            </button>
        )
    }
    return (
        <button
            onClick={() => dispatch(selectProvider({...provider, action: "edit"}))}
            className={`paper-manager-provider-container ${providerSelected.email === provider.email ? "active": "inactive"}`}
        >
            <CustomAvatar 
                label={provider.name}
            />
            <div className="paper-manager-provider-content">
                <div className="paper-manager-provider-body">
                    <span className="paper-manager-provider-title">{provider.name}</span>
                    <span className="paper-manager-provider-subtitle">{provider.email}</span>
                </div>
                <IconButton 
                    disabled={loading}
                    onClick={handleOnDelete}
                >
                    <DeleteIcon style={{color: "var(--blue)"}}/>
                </IconButton>
            </div>
        </button>
    )   
}

export default PaperManagerProvider