import { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import { InputMask } from '@react-input/mask';

import './styles.css'

const CellPhoneMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="(__) _____-____" replacement={{ _: /\d/ }}  {...props} />;
});

const CredCardMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="____ ____ ____ ____" replacement={{ _: /\d/ }}  {...props} />;
});

const ExpCardMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="__/__" replacement={{ _: /\d/ }}  {...props} />;
});

const DocumentMask = forwardRef((props, forwardedRef) => {
    const modify = (input) => {
        return { mask: input.length > 11 ? '__.___.___/____-__' : undefined }
    };

    return <InputMask ref={forwardedRef} mask='___.___.___-___' replacement={{ _: /\d/ }} modify={modify} {...props} />;
});

const CEPMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="__.___-___" replacement={{ _: /\d/ }}  {...props} />;
});

const InputText = ({
    label,
    rows,
    multiline,
    value,
    onChange,
    onBlur,
    type,
    error,
    helperText,
    style,
    mask,
    placeholder,
    disabled
}) => {

    const masks = {
        cellphone: CellPhoneMask,
        card: CredCardMask,
        expcard: ExpCardMask,
        cep: CEPMask,
        document: DocumentMask
    }

    return (
        <div style={style}>
            <TextField
                disabled={disabled}
                className='input-text-field-container' 
                placeholder={placeholder}
                error={error}
                type={type}
                rows={rows}
                size="small"
                label={label}
                variant="outlined" 
                multiline={multiline}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                slotProps={{
                    input: {
                        inputComponent: masks[mask]
                    }
                }}
            />
            {error && <span style={{fontSize: 10, color: "#d32f2f", marginLeft: 6}}>{helperText}</span>}
        </div>
    )
}

export default InputText;