import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { saveAs } from "file-saver"
import * as XLSX from "xlsx"

import * as solicitationService from '../../../service/solicitation'

import Button from "../../../components/Button"
import InputSelect from "../../../components/Input/Select"
import Layout from "../../../components/Layout"
import SolicitationsTable from "../../../components/Table/Solicitations"

import Depression from '../../../assets/vector/depression.svg'

import './styles.css'
import Title from "../../../components/Title"

const getDateLabel = () => {
    const today = new Date();
  
    const formatDate = (date) => {
        return date.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };
  
    const startOfWeek = (date) => {
        const day = date.getDay(); // Obtém o dia da semana (0 para domingo, 6 para sábado)
        const diff = date.getDate() - day; // Ajusta para domingo
        return new Date(date.setDate(diff));
    };
  
    const endOfWeek = (date) => {
        const start = startOfWeek(new Date(date)); // Calcula o início da semana (domingo)
        return new Date(start.setDate(start.getDate() + 6)); // Ajusta para sábado
    };
  
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
    const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
  
    return [
        { id: "today", label: `Hoje: ${formatDate(today)}` },
        { id: "yesterday", label: `Ontem: ${formatDate(new Date(today.setDate(today.getDate() - 1)))}` },
        {
            id: "thisWeek",
            label: `Esta Semana: ${formatDate(startOfWeek(new Date()))} - ${formatDate(endOfWeek(new Date()))}`,
        },
        {
            id: "lastWeek",
            label: `Semana Passada: ${formatDate(
                startOfWeek(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)) // Referência consistente para semana passada
            )} - ${formatDate(
                endOfWeek(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)) // Mesmo ponto de referência
            )}`,
        },
        {
            id: "thisMonth",
            label: `Este Mês: ${formatDate(startOfMonth)} - ${formatDate(endOfMonth)}`,
        },
        {
            id: "lastMonth",
            label: `Mês Passado: ${formatDate(startOfLastMonth)} - ${formatDate(endOfLastMonth)}`,
        },
        //{ id: "customRange", label: "Personalizado" },
    ];
}

const Historic = () => {
    const user = useSelector(state => state.user.data)
    const [dateRangeChanged, setChangeDateRange] = useState(false)
    const [dateRangesSelected, setDateRange] = useState("yesterday")

    const [data, setData] = useState([])
    const [loadData, setLoadData] = useState(true)
    const [loadingData, setLoadingData] = useState(false)
    const [loadingDataButton, setLoadingDataButton] = useState(false)
    const [loadingExportDataButton, setExportLoadingDataButton] = useState(false)
    
    const dateRanges = getDateLabel()

    const handleChangeDateRange = (value) => {
        setDateRange(value)
        setChangeDateRange(true)
    }

    const handleOnConfirm = async () => {
        setLoadingDataButton(true)
        await getAllSolicitaitonByCompany()
        setChangeDateRange(false)
        setLoadingDataButton(false)
    }

    const getAllSolicitaitonByCompany = async () => {
        setLoadingData(true)

        const response = await solicitationService.getAllSolicitaitonByCompany({
            uuidCompany: user.uuidCompany,
            dateRange: dateRangesSelected
        })

        console.log(response)

        if (response) setData((prev) => [...response]);

        setLoadingData(false)
    }

    const exportToExcel = () => {
        setExportLoadingDataButton(true)
        const services = {
            mecanico: 'Mecânico',
            bateria: 'Carga de Bateria',
            chaveiro: 'Chaveiro',
            combustivel: 'Combustível',
            pneu: 'Troca de Pneus',
            reboque: "Reboque"
        }
        
        const formattedData = data.map(row => ({
            Placa: row.placa,
            "Criado em": `${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toLocaleTimeString()}`,
            Serviço: services[row.service],
            Valor: Number(row.price).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
            Protocolo: row.validateCode
        }));
        
        const worksheet = XLSX.utils.json_to_sheet(formattedData)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "servicos")
        
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
        const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" })
        saveAs(dataBlob, "servicos.xlsx")
        setExportLoadingDataButton(false)
    }

    useEffect(() => {
        if (Object.keys(user).length > 0 && loadData) {
            setLoadData(false)
            getAllSolicitaitonByCompany()
        }
    }, [loadData, user])

    return (
        <Layout>
            <div className="historic-container">
                <div className="historic-content">
                    <div className="historic-head">
                        <Title label="Histórico de Serviços Atendidos"/>
                        <div className="historic-head-content">
                            <InputSelect
                                label="Período"
                                value={dateRangesSelected}
                                options={dateRanges}
                                onChange={(event) => handleChangeDateRange(event.target.value)}
                            />
                            {dateRangeChanged &&
                                <Button 
                                    label="Confirmar"
                                    loading={loadingDataButton}
                                    onClick={handleOnConfirm}
                                />
                            }
                            {!loadingData && data.length > 0 &&
                                <Button 
                                    kind="secondary"
                                    label="Baixar relatório"
                                    loading={loadingExportDataButton}
                                    onClick={exportToExcel}
                                />
                            }
                        </div>
                    </div>
                    <div className="historic-body">
                        <div className="historic-body-hr"/>
                        {data.length > 0 ? (
                            <SolicitationsTable data={data}/>
                        ) : (
                            <div className="historic-body-empty-content">
                                <img src={Depression} alt=""/>
                                <span>Nenhum serviço encontrado no período selecionado.</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Historic