import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import * as providersServices from '../../../service/provider'
import * as servicesServies from '../../../service/services'

import { loadProviders, selectProvider } from "../../../action/managerProviders"

import Layout from "../../../components/Layout"
import Title from "../../../components/Title"

import './styles.css'
import { loadServices } from "../../../action/services"
import InputText from "../../../components/Input/Text"
import PaperManagerProvider from "../../../components/Paper/ManagerProvider"
import PaperServiceDense from "../../../components/Paper/Service/Dense"
import Button from "../../../components/Button"

const Providers = () => {
    const dispatch = useDispatch()

    const user = useSelector(state => state.user.data)
    const activeServices = useSelector(state => state.services.list)  
    const providers = useSelector(state => state.managerProviders.list)
    const providerSelected = useSelector(state => state.managerProviders.selected)

    const [filter, setFilter] = useState()
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [loadingServices, setLoadingServices] = useState(false)
    const [loadingProviders, setLoadingProviders] = useState(false)

    const [newProvider, setNewProvider] = useState({
        name: "",
        email: "",
        cellphone: ""
    })

    const [serviceSelected, setServiceSelected] = useState([])

    const handleNewProvider = (column, value) => {
        setNewProvider({
            ...newProvider,
            [column]: value
        })
    }

    const handleSetService = (service) => {
        setServiceSelected((prevServices) => {
            // Verifica se o serviço já está na lista
            if (prevServices.includes(service)) {
                // Se já estiver, remove o serviço da lista
                return prevServices.filter((s) => s !== service);
            } else {
                // Se não estiver, adiciona o serviço à lista
                return [...prevServices, service];
            }
        });
    }

    const getProviders = async () => {
        setLoadingProviders(true)
        const response = await providersServices.getProviders({
            uuidCompany: user.uuidCompany
        })

        if (response) {
            dispatch(loadProviders(response))
        }
        setLoadingProviders(false)
    }

    const getServices = async () => {
        setLoadingServices(true)
        const response = await servicesServies.getServices({
            uuidCompany: user.uuidCompany
        })
        if (response) {
            dispatch(loadServices(response.Services))
        }
        setLoadingServices(false)
    }

    const validateForm = () => {
        if (newProvider.name === "") return true
        if (newProvider.email === "") return true
        if (newProvider.cellphone === "") return true
        if (serviceSelected.action === "add" && serviceSelected.length === 0) return true
        return false
    }

    const submit = async () => {
        setLoadingSubmit(true)
        
        const provider = {
            name: newProvider.name,
            email: newProvider.email,
            cellphone: newProvider.cellphone
        }

        if (providerSelected.action === "add") {
            const promises = serviceSelected.map(async(item) => {
                const serviceData = activeServices.filter(a => a.service === item)[0]
    
                await providersServices.setProvider({
                    ...provider,
                    status: "inactive",
                    service: serviceData.service,
                    uuidService: serviceData.uuid,
                    uuidCompany: serviceData.uuidCompany,
                    identityCompany: serviceData.identityCompany
                })
            })

            await Promise.all(promises)
            window.location.reload()
        } else {
            const oldService = providerSelected.services.map(item => item.service)
            const addService = serviceSelected.filter(item => !oldService.includes(item))

            if (addService.length > 0) {
                const promises = addService.map(async(item) => {
                    const serviceData = activeServices.filter(a => a.service === item)[0]
        
                    await providersServices.setProvider({
                        ...provider,
                        status: "inactive",
                        service: serviceData.service,
                        uuidService: serviceData.uuid,
                        uuidCompany: serviceData.uuidCompany,
                        identityCompany: serviceData.identityCompany
                    })
                })

                await Promise.all(promises)
            }

            const promises = providerSelected.services.map(async(item) => {
                const serviceData = activeServices.filter(a => a.service === item.service)[0]
                    
                await providersServices.updateProvider({
                    ...provider,
                    status: "inactive",
                    uuid: item.uuidProvider,
                    service: serviceData.service,
                    uuidService: serviceData.uuid,
                    uuidCompany: serviceData.uuidCompany,
                    identityCompany: serviceData.identityCompany,
                    delete: !serviceSelected.includes(item.service),
                })
            })

            await Promise.all(promises)
            window.location.reload()
        }
        setLoadingSubmit(false)
    }

    useEffect(() => {
        if(Object.keys(user).length > 0) {
            getProviders()
            getServices()
        }
    }, [user])

    useEffect(() => {
        if (providerSelected.action !== "add") {
            setNewProvider({
                name: providerSelected.name,
                email: providerSelected.email,
                cellphone: providerSelected.cellphone
            })

            setServiceSelected(providerSelected.services.map(item => item.service))
        } else {
            setServiceSelected([])
            setNewProvider({
                name: "",
                email: "",
                cellphone: ""
            })
        }
    }, [providerSelected])

    return (
        <Layout>
            <div className="providers-container">
                <div className="providers-content">
                    <Title label="Gerenciar Prestadores"/>
                    <div className="providers-body">
                        <div className="providers-list-container">
                            <InputText 
                                label="Pesquisa prestador por E-mail"
                                value={filter}
                                onChange={(event) => setFilter(event.target.value)}
                            />
                            <div className="providers-list-content">
                                <div className="providers-list-grid">
                                    <PaperManagerProvider add/>
                                    {providers.filter(item => {
                                        if (!filter || filter.trim() === "") {
                                            return true
                                        }
                                        return item.name.includes(filter)
                                    }).map((item, index) => <PaperManagerProvider key={index}provider={item}/>)}
                                </div>
                            </div>
                        </div>
                        <div className="manger-provider-container">
                            <div>{/*dados basicos*/}
                                <span className='new-provider-forms-title'>{providerSelected.action === "add" ? "Adicionar": "Editar Dados do"} Prestador</span>
                                <div className='new-provider-forms-grid'>
                                    <InputText 
                                        label="Nome"
                                        value={newProvider.name}
                                        onChange={(event) => handleNewProvider("name", event.target.value)}
                                    />
                                    <InputText 
                                        label="E-mail"
                                        value={newProvider.email}
                                        onChange={(event) => handleNewProvider("email", event.target.value)}
                                    />
                                    <InputText 
                                        label="Telefone"
                                        mask="cellphone"
                                        value={newProvider.cellphone}
                                        onChange={(event) => handleNewProvider("cellphone", event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="new-provider-forms-item">{/*serviços*/}
                                <div className="new-provider-forms-title-group">
                                    <span className='new-provider-forms-title'>Serviços Ativos</span>
                                    <span>
                                        Esses são os serviços ativos em sua empresa. 
                                        Selecione abaixo os serviços que esse prestador está apto a atender.
                                    </span>
                                </div>
                                <div className='new-services-list-grid'>
                                    {activeServices.map((item, index) => (
                                        <PaperServiceDense 
                                            key={index}
                                            service={item}
                                            active={serviceSelected.includes(item.service)}
                                            onClick={() => handleSetService(item.service)}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="new-provider-forms-item">{/*footer*/}
                                <Button 
                                    loading={loadingSubmit}
                                    disabled={validateForm()}
                                    label={providerSelected.action === "add" ? "Adicionar Prestador" : "Editar Dados do Prestador"}
                                    onClick={submit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Providers