import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../action/user";

export const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();
    const [ user ] = useLocalStorage("user", null)

    const dispatch = useDispatch()

    const pathName = window.location.pathname

    useEffect(() => {
        if (user) {
            dispatch(updateUser(user))
        }
    }, [user])
    
    if (!token) return <Navigate to="/auth" />
    else if (user.type === "employee" && pathName === "/acessos") return <Navigate to="/" />
    return children;
};