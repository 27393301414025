import { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';

import './styles.css'

const SolicitationsTable = ({
    data
}) => {
    const [orderDirection, setOrderDirection] = useState('desc')
    const [sortedData, setSortedData] = useState([])

    const columns = [
        "placa",
        "createdAt",
        "service",
        "price",
        "validateCode",
    ]

    const formatColunsName = {
        validateCode: "Protocolo",
        vehicle: "Veículo",
        service: "Serviço",
        price: "Valor",
        createdAt: "Criado em",
        placa: "Placa"
    }

    const services = {
        mecanico: 'Mecânico',
        bateria: 'Carga de Bateria',
        chaveiro: 'Chaveiro',
        combustivel: 'Combustível',
        pneu: 'Troca de Pneus',
        reboque: "Reboque"
    }

    const handleSort = () => {
        const isAscending = orderDirection === 'asc';
        const sorted = [...sortedData].sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return isAscending ? dateA - dateB : dateB - dateA;
        });

        setSortedData(sorted);
        setOrderDirection(isAscending ? 'desc' : 'asc');
    }

    useEffect(() => {
        const isAscending = orderDirection === 'asc'

        const sorted = [...data].sort((a, b) => {
            const dateA = new Date(a.createdAt)
            const dateB = new Date(b.createdAt)

            return isAscending ? dateA - dateB : dateB - dateA;
        })

        setSortedData(sorted); 
    }, [data]);

    return (
        <TableContainer 
            component={Paper} 
            className='solicitations-table-container'
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((col, index) => {
                            return (
                                <TableCell key={index}>
                                    {col === 'createdAt' ? (
                                        <TableSortLabel
                                            active
                                            direction={orderDirection}
                                            onClick={handleSort}
                                        >
                                            {formatColunsName[col]}
                                        </TableSortLabel>
                                    ) : (
                                        formatColunsName[col]
                                    )}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map(row => {
                        return (
                            <TableRow>
                               {columns.map((col, index) => {
                                    if (col === "service") return <TableCell key={index}>{services[row[col]]}</TableCell>
                                    if (col === "price") return <TableCell key={index}>{Number(row[col]).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}</TableCell>
                                    if (col === "createdAt") return <TableCell key={index}>{`${new Date(row[col]).toLocaleDateString()} ${new Date(row[col]).toLocaleTimeString()}`}</TableCell>
                                    return <TableCell key={index}>{row[col]}</TableCell>
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer >
    )
}

export default SolicitationsTable