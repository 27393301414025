import api from "./api"

export const getProviders = async (params) => {
    try {
        const response = await api.get('/service/getProvidersByCompany', {params})
        if(response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("getProviders", error)
    }
}

export const setProvider = async (data) => {
    try {
        const response = await api.post('/service/providers', data)
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("setProvider", error)
    }
}

export const updateProvider = async (data) => {
    try {
        const response = await api.put('/service/providers', data)
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("updateProvider", error)
    }
}
