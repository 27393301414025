import { useDispatch, useSelector } from 'react-redux'

import { deleteService, setServiceSelected } from '../../../action/services'

import * as servicesServices from '../../../service/services'

import MecanicoIcon from '../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../assets/icon/small-combustivel.png'
import PneusIcon from '../../../assets/icon/small-pneus.png'
import ReboqueIcon from '../../../assets/icon/small-reboque.png'

import { Avatar, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import './styles.css'
import { useState } from 'react'

const PaperService = ({
    service,
    add
}) => {
    const dispatch = useDispatch()

    const serviceSelected = useSelector(state => state.services.selected)

    const [loading, setLoading] = useState(false)

    const servicesIcon = {
        'Mecânico': MecanicoIcon,
        'Carga de Bateria': BateriaIcon,
        'Chaveiro': ChaveiroIcon,
        'Combustível': CombustivelIcon,
        'Troca de Pneus': PneusIcon,
        "Reboque": ReboqueIcon,
    }

    const onDelete = async () => {
        setLoading(true)
        const response = await servicesServices.deleteServices(service)
        if (response) {
            dispatch(deleteService(service))
        }
        setLoading(false)
    }

    if (add) {
        return (
            <button 
                className='paper-service-container'
                onClick={() => dispatch(setServiceSelected({service: "add"}))}
            >
                <Avatar>
                    <AddIcon />
                </Avatar>
                <div className='paper-service-content'>
                    <div className='paper-service-body'>
                        <span className='paper-service-title'>Ativar Novo Serviço</span>
                    </div>
                </div>
            </button>
        )
    }

    return (
        <button 
            className={`paper-service-container ${serviceSelected.uuid === service.uuid ? "active": "inactive"}`}
            onClick={() => dispatch(setServiceSelected(service))}
        >
            <img src={servicesIcon[service.service]}/>
            <div className='paper-service-content'>
                <div className='paper-service-body'>
                    <span className='paper-service-title'>{`${service.service}`}</span>
                    <span className='paper-service-subtitle'>{`Raio de atendimento: ${service.serviceRadius} km`}</span>
                </div>
                <IconButton disabled={loading} onClick={onDelete}>
                    <DeleteIcon style={{color: "var(--blue)"}}/>
                </IconButton>
            </div>
        </button>
    )
}
export default PaperService