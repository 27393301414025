import { createSlice } from '@reduxjs/toolkit'

export const servicesSlice = createSlice({
    name: "services",
    initialState: {
        list: [],
        active: [],
        selected: {
            service: "add"
        },
    },
    reducers: {
        loadServices: (state, action) => {
            state.list = action.payload
        },
        loadActiveService: (state, action) => {
            state.active = action.payload.map(item => {
                if (item.name === "Socorro Bateria") {
                    item.name = "Carga de Bateria"
                }

                return item
            })
        },
        setServiceSelected: (state, action) => {
            state.selected = action.payload
        },
        deleteService: (state, action) => {
            const { uuid } = action.payload

            state.list = state.list.filter(service => service.uuid !== uuid)
            state.selected = {service: "add"}
        },
        insertService: (state, action) => {
            state.list.push(action.payload)
        },
        updateService: (state, action) => {
            const { uuid } = action.payload

            state.list = state.list.map(item => {
                if (item.uuid === uuid) return action.payload
                return item
            })
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    loadServices,
    deleteService,
    insertService,
    updateService,
    loadActiveService,
    setServiceSelected,
} = servicesSlice.actions

export default servicesSlice.reducer