import MecanicoIcon from '../../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../../assets/icon/small-combustivel.png'
import PneusIcon from '../../../../assets/icon/small-pneus.png'
import ReboqueIcon from '../../../../assets/icon/small-reboque.png'

import './styles.css'

const PaperServiceDense = ({
    service,
    active,
    onClick
}) => {
    const servicesIcon = {
        'Mecânico': MecanicoIcon,
        'Carga de Bateria': BateriaIcon,
        'Chaveiro': ChaveiroIcon,
        'Combustível': CombustivelIcon,
        'Troca de Pneus': PneusIcon,
        "Reboque": ReboqueIcon,
    }

    return (
        <button 
            onClick={onClick}
            className={`paper-service-dense-container ${active ? "active" : "inactive"}`}
        >
            <img src={servicesIcon[service.name ? service.name : service.service]}/>
            <span>{service.name ? service.name : service.service}</span>
        </button>
    )
}

export default PaperServiceDense