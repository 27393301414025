import './styles.css'

const PaperServiceRadius = ({
    radius,
    active,
    onClick,
}) => {
    return (
        <button
            className={`paper-service-radius-container ${active ? "active" : "inactive"}`}
            onClick={onClick}
        >
            {`${radius} km`}
        </button>
    )
}

export default PaperServiceRadius