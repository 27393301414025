import api from "./api"

export const getServices = async (params) => {
    try {
        const response = await api.get('/service', {params})
        if(response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("getServices", error)
    }
}

export const getActiveServices = async (params) => {
    try {
        const response = await api.get('/service/active')
        if(response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("getServices", error)
    }
}

export const deleteServices = async (service) => {
    try {
        const response = await api.delete('/service', {data: service})
        if(response.status === 200) return true
        return false
    } catch (error) {
        console.log("deleteServices", error)
    }
}

export const saveService = async (service) => {
    try {
        const response = await api.post('/service', service)
        if(response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("saveService", error)
    }
}

export const updateService = async (service) => {
    try {
        const response = await api.put('/service', service)
        if(response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("updateService", error)
    }
}