import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as servicesServies from '../../../service/services'

import Layout from "../../../components/Layout"
import Title from "../../../components/Title"

import './styles.css'
import { insertService, loadActiveService, loadServices, setServiceSelected, updateService } from '../../../action/services'
import PaperService from '../../../components/Paper/Service'
import PaperServiceDense from '../../../components/Paper/Service/Dense'
import PaperServiceRadius from '../../../components/Paper/Service/Radius'
import InputText from '../../../components/Input/Text'
import InputSelect from '../../../components/Input/Select'
import Button from '../../../components/Button'

const Services = () => {
    const dispatch = useDispatch()

    const user = useSelector(state => state.user.data)
    const services = useSelector(state => state.services.list)
    const serviceSelected = useSelector(state => state.services.selected)
    const activeServices = useSelector(state => state.services.active)

    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [loadingServices, setLoadingServices] = useState(false)
    const [loadingActiveServices, setLoafingActiveServices] = useState(false)

    const [serviceValue, setServiceValue] = useState("")
    const [newService, setNewService] = useState({
        service: "",
        serviceRadius: "",
        feeRules: {
            role: "",
            type: "",
            fee: 0
        }
    })

    const handleNewService = (column, value) => {
        setNewService({
            ...newService,
            [column]: value
        })
    }

    const handleFeeRules = (column, value) => {
        if (column === "role" && value === "forward") {
            setNewService({
                ...newService,
                feeRules: {
                    ...newService.feeRules,
                    [column]: value,
                    type: "fix"
                }
            })
        } else {
            setNewService({
                ...newService,
                feeRules: {
                    ...newService.feeRules,
                    [column]: value
                }
            })
        }

    }

    const getServices = async () => {
        setLoadingServices(true)
        const response = await servicesServies.getServices({
            uuidCompany: user.uuidCompany
        })
        if (response) {
            dispatch(loadServices(response.Services))
        }
        setLoadingServices(false)
    }

    const getServicesActive = async () => {
        setLoafingActiveServices(true)
        const response = await servicesServies.getActiveServices()
        if (response) {
            dispatch(loadActiveService(response))
        }
        setLoafingActiveServices(false)
    }

    const printFee = () => {
        if (newService.feeRules.type === "percent") return ` ${Number(newService.feeRules.fee)}%`
        return ` ${Number(newService.feeRules.fee).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
    }

    const calculateAmountToCharge = () => {
        if (newService.feeRules.type === "fix") return ` ${Number(newService.feeRules.fee).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
        if (newService.feeRules.role === "discount") return ` ${Number(serviceValue.replace(',', '.') * (newService.feeRules.fee / 100)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
        if (newService.feeRules.role === "markup") {
            if (newService.feeRules.type !== "fix") return ` ${Number(serviceValue.replace(',', '.') * (newService.feeRules.fee / 100)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
        }
        return ` ${(Number(serviceValue.replace(',', '.')) - Number(newService.feeRules.fee)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
    }

    const calculateAmountToTransfer = () => {
        if (newService.feeRules.fee === 0) return  ` ${Number(0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
        if (newService.feeRules.type === "fix" && newService.feeRules.role === "discount") return ` ${(Number(serviceValue.replace(',', '.')) - Number(newService.feeRules.fee.replace(',', '.'))).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}` 
        if (newService.feeRules.role === "markup") return ` ${Number(serviceValue.replace(',', '.')).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
        if (newService.feeRules.role === "forward") return ` ${Number(newService.feeRules.fee.replace(',', '.')).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
        return ` ${(Number(serviceValue.replace(',', '.')) - (Number(serviceValue.replace(',', '.') * (newService.feeRules.fee / 100)))).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
    }

    const calculateFinalValue = () => {
        if (newService.feeRules.type !== "fix") {
            return ` ${(Number(serviceValue.replace(',', '.')) + Number(serviceValue.replace(',', '.') * (newService.feeRules.fee / 100))).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
        }
        return ` ${(Number(serviceValue.replace(',', '.')) + Number(newService.feeRules.fee)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`
    }

    const validateForm = () => {
        if (newService.service === "") return true
        if (newService.serviceRadius === "") return true
        if (newService.feeRules.role === "") return true
        if (newService.feeRules.type === "") return true
        if (newService.feeRules.fee === 0) return true
        return false
    }

    const submit = async () => {
        setLoadingSubmit(true)
        if (serviceSelected.service === "add") {
            const response = await servicesServies.saveService({
                ...newService,
                identityCompany: user.identityCompany,
                uuidCompany: user.uuidCompany,
            })
    
            if (response) {
                dispatch(insertService(response.Service))
                setNewService({
                    service: "",
                    serviceRadius: "",
                    feeRules: {
                        role: "",
                        type: "",
                        fee: 0
                    }
                })
            }
        } else {
            const response = await servicesServies.updateService({
                ...newService,
                uuidService: serviceSelected.uuid,
                identityCompany: user.identityCompany,
                uuidCompany: user.uuidCompany,
            })
    
            if (response) {
                dispatch(updateService(response.Service))
                dispatch(setServiceSelected({service: "add"}))
                setNewService({
                    service: "",
                    serviceRadius: "",
                    feeRules: {
                        role: "",
                        type: "",
                        fee: 0
                    }
                })
            }
        }

        setLoadingSubmit(false)
    }

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            getServices()
            getServicesActive()
        }
    }, [user])

    useEffect(() => {
        if (serviceSelected.service !== "add") {
            const feeRulesDefault = {role: "",type: "",fee: 0}

            setNewService({
                service: serviceSelected.service,
                serviceRadius: serviceSelected.serviceRadius,
                feeRules: serviceSelected.feeRules ? serviceSelected.feeRules : feeRulesDefault
            })
        }
    }, [serviceSelected])

    return (
        <Layout>
            <div className="services-container">
                <div className="services-content">
                    <Title label="Meus Serviços"/>
                    <div className="services-body">
                        <div className="services-list-grid">
                            <PaperService add/>
                            {services.map((item, index) => {
                                return <PaperService key={index} service={item}/>
                            })}
                        </div>
                        <div>
                            {serviceSelected.service === "add" && (
                                <div>{/*Serviço*/}
                                    <span className='new-services-forms-title'>Selecione um tipo de serviço</span>
                                    <div className='new-services-list-grid'>
                                        {activeServices
                                            .filter(item => !services.some(service => service.service === item.name))
                                            .map((item, index) => (
                                                <PaperServiceDense 
                                                    key={index}
                                                    service={item}
                                                    active={newService.service === item.name}
                                                    onClick={() => handleNewService("service", item.name)}
                                                />
                                            ))}
                                    </div>
                                </div>
                            )} 
                            <div className={serviceSelected.service === "add" && 'new-services-forms-item'}>{/*Raio*/}
                                <span className='new-services-forms-title'>Raio de atendimento</span>
                                <div className='new-service-radius-grid'>
                                    {[20, 40, 60, 80, 100].map((item, index) => {
                                        return (
                                            <PaperServiceRadius 
                                                key={index}
                                                radius={item}
                                                active={newService.serviceRadius === item}
                                                onClick={() => handleNewService("serviceRadius", item)}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='new-services-forms-item'>{/*Repasse*/}
                                <span className='new-services-forms-title'>Regra de repasse</span>
                                <div className='new-service-fee-grid'>
                                    <InputSelect 
                                        label="Regra"
                                        value={newService.feeRules.role}
                                        onChange={(event) => handleFeeRules("role", event.target.value)}
                                        options={[
                                            {id: "discount", label: "Descontar"},
                                            {id: "markup", label: "Acrescentar"},
                                            {id: "forward", label: "Repassar"}
                                        ]}
                                    />
                                    <InputSelect 
                                        label="Tipo de taxa"
                                        value={newService.feeRules.type}
                                        onChange={(event) => handleFeeRules("type", event.target.value)}
                                        options={[
                                            {id: "fix", label: "Valor fixo"},
                                            newService.feeRules.role !== "forward" && {id: "percent", label: "Percentual"},
                                        ]}
                                    />
                                    <InputText 
                                        label="Taxa"
                                        placeholder="Digites números inteiros"
                                        value={newService.feeRules.fee}
                                        onChange={(event) => handleFeeRules("fee", event.target.value)}
                                    />
                                </div>
                                <div className='new-service-fee-simulation-container'>
                                    <span className='new-service-fee-simulation-title'>Simulação:</span>
                                    <InputText 
                                        label={newService.feeRules.role === "markup" ? "Valor da proposta" : "Valor do serviço"}
                                        value={serviceValue}
                                        onChange={(event) => setServiceValue(event.target.value)}
                                        style={{marginTop: 16}}
                                    />
                                    <ul className='new-service-fee-simulation-result'>
                                        <li>
                                            {newService.feeRules.role === "markup" ? "Valor da proposta" : "Valor do serviço"}:
                                            <span>{` ${Number(serviceValue.replace(',', '.')).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`} </span>
                                        </li>
                                        <li>
                                            {`Taxa ${newService.feeRules.role === "discount" ? "a cobrar: " : newService.feeRules.role === "markup" ? "a adicionar: " : "a repassar: "}`}
                                            <span>{printFee()}</span>
                                        </li>
                                        <li>Valor do cobrado: <span>{calculateAmountToCharge()}</span></li>
                                        <li>Valor do repassado: <span>{calculateAmountToTransfer()}</span></li>
                                        {newService.feeRules.role === "markup" &&
                                            <li>Valor final: <span>{calculateFinalValue()}</span></li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className='new-services-forms-item'>{/*Footer*/}
                                <Button 
                                    disabled={validateForm()}
                                    label={serviceSelected.service === "add" ? "Ativar Serviço" : "Editar Configurações do Serviço"}
                                    loading={loadingSubmit}
                                    onClick={submit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Services