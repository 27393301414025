import { Routes, Route } from "react-router-dom"
import { ProtectedRoute } from './Protected'

import Auth from '../pages/Auth'
import Home from '../pages/App/Home'
import Access from "../pages/App/Access"
import Historic from "../pages/App/Historic"
import Services from "../pages/App/Services"
import Providers from "../pages/App/Providers"

const Router = () => {
    return (
        <Routes>
            <Route path="/auth" element={<Auth />}/>
            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>}/>
            <Route path="/acessos" element={<ProtectedRoute><Access /></ProtectedRoute>}/>
            <Route path="/historic" element={<ProtectedRoute><Historic /></ProtectedRoute>}/>
            <Route path="/services" element={<ProtectedRoute><Services /></ProtectedRoute>}/>
            <Route path="/providers" element={<ProtectedRoute><Providers /></ProtectedRoute>}/>
        </Routes>
    )
}

export default Router;