import { createSlice } from '@reduxjs/toolkit'

export const managerProvidersSlice = createSlice({
    name: "managerProvider",
    initialState: {
        list: [],
        selected: {
            action: "add"
        }
    },
    reducers: {
        loadProviders: (state, action) => {
            state.list = action.payload
        },
        selectProvider: (state, action) => {
            state.selected = action.payload
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    loadProviders,
    selectProvider
} = managerProvidersSlice.actions

export default managerProvidersSlice.reducer