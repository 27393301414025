import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import * as serviceProposalService from '../../../service/serviceProposals'

import { updateUser } from "../../../action/user"
import { useWebSocket } from "../../../hooks/useWebSocket"
import { useLocalStorage } from "../../../hooks/useLocalStorage"

import Layout from "../../../components/Layout"
import MapView from "../../../components/MapView"
import PaperNewSolicitation from "../../../components/Paper/NewSolicitation"

import './styles.css'
import { loadServiceProposals } from "../../../action/serviceProposals"
import PaperServiceProposals from "../../../components/Paper/ServiceProposal"
import RedirectServiceModal from "../../../components/Modal/RedirectService"
import DoneVector from '../../../assets/vector/done.svg'
import { useSearchParams } from "react-router-dom"

import * as solicitationService from '../../../service/solicitation'
import { setNewSolicitation } from "../../../action/solicitations"

const Home = () => {
    const dispatch = useDispatch()
    const { startWebSocket } = useWebSocket()
    
    const [ searchParams ] = useSearchParams()
    const uuidSolicitation = searchParams.get("solicitacao")

    const user = useSelector(state => state.user.data)
    const device = useSelector(state => state.user.device)
    const providers = useSelector(state => Object.values(state.providers.list))
    const solicitation = useSelector(state => state.solicitations.selected)
    const newSolicitation = useSelector(state => state.solicitations.newSolicitation)
    const serviceProposals = useSelector(state => state.serviceProposals.list)
    const webScocketConnection = useSelector(state => state.webSocket.connection)

    const handleLoadServiceProposals = async () => {
        const response = await serviceProposalService.getServiceProposalsByCompany(user.uuidCompany)
        if (response) {
            dispatch(loadServiceProposals(response))
        }
    }

    useEffect(() => {
        if (user && webScocketConnection.readyState === WebSocket.CLOSED) {
            startWebSocket(user.uuid, user.uuidCompany)
        }
    }, [user, webScocketConnection.readyState])

    useEffect(() => {
        if (Object.keys(user).length > 0) handleLoadServiceProposals()
    }, [user])

    useEffect(() => {
        if (uuidSolicitation) {
            (async () => {
                const response = await solicitationService.getSolicitation(uuidSolicitation)
                if (response) {
                    dispatch(setNewSolicitation(response))
                }
            })()
        }
    }, [uuidSolicitation])

    return (
        <>
            <RedirectServiceModal />
            <Layout>
                <div className="home-container">
                    {Object.keys(newSolicitation).length > 0 || serviceProposals.length > 0 ? (
                        <div className="home-menu-container">
                            {Object.keys(newSolicitation).length > 0 &&
                                <div className="home-menu-item">
                                    <span className="home-menu-item-title">Nova solicitação</span>
                                    <div>
                                        <PaperNewSolicitation />
                                    </div>
                                </div>
                            }
                            {serviceProposals.length > 0 &&
                                <div className="home-menu-item">
                                    <span className="home-menu-item-title">Solicitações em andamento</span>
                                    <div>
                                        {serviceProposals.map((serivceProposal, index) => {
                                            return <PaperServiceProposals key={index} serviceProposal={serivceProposal}/>
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    ) : (
                        <div className="home-menu-container-done">
                            <img src={DoneVector}/>
                            <span> Tudo calmo por aqui!! </span>
                        </div>
                    )}
                    {device !== "mobile" &&
                        <div className="home-map-container">
                            {Object.keys(user).length > 0 &&
                                <MapView origin={user.company.coords} providers={providers} solicitation={solicitation}/>
                            }
                        </div>
                    }
                </div>
            </Layout>
        </>
    )
}

export default Home